<template>
  <div>
    <el-form :model="vendor" ref="vendorForm" label-width="80px" class="demo-ruleForm" :size="small">
      <el-row>
        <el-col :span="8">
          <el-form-item label="名称">
            <el-input v-model="vendor.vendorName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="代码">
            <el-input v-model="vendor.vendorCode"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="状态">
            <el-select v-model="vendor.vendorStatus" placeholder="供应商状态">
              <el-option label="全部" value=""></el-option>
              <el-option label="未激活" value="0"></el-option>
              <el-option label="已激活，未锁定" value="1"></el-option>
              <el-option label="已锁定" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-button type="primary" icon="el-icon-search" :size="small" @click="findByPage">搜索</el-button>
        <el-button type="primary" icon="el-icon-plus" :size="small" @click="handleCreate">新增</el-button>
      </el-row>
    </el-form>

    <el-table
        :data="tableData"
        :height="tableHeight"
        border
        :size="small"
        style="width: 100%;margin-top: 10px;"
        v-loading="loading"
        ref="topictable"
    >
      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column
          prop="id"
          label="id"
          width="120">
      </el-table-column>
      <el-table-column
          prop="vendorName"
          label="供应商名称"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="vendorCode"
          label="供应商代码"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="vendorTaxCode"
          label="供应商税号"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="vendorAddress"
          label="供应商地址"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="vendorBank"
          label="供应商开户行"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="createdTime"
          label="创建时间"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          prop="vendorStatus"
          label="供应商状态"
          :formatter="formatStatus"
          show-overflow-tooltip>
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="200">
        <template slot-scope="{row}">
          <el-button type="text" :size="small" @click="handleUpdate(row)">编辑</el-button>
          <el-button type="text" :size="small" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageTotal"
        style="margin-top: 10px">
    </el-pagination>

    <el-dialog :title="formStatusMap[formStatus]" :visible.sync="dialogFormVisible" :center="true">
      <el-form ref="vendorRef" :rules="rules" :model="vendorForm" size="small">
        <el-form-item label="供应商代码" prop="vendorCode" :label-width="formLabelWidth">
          <el-input v-model="vendorForm.vendorCode" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="供应商名称" prop="vendorName" :label-width="formLabelWidth">
          <el-input v-model="vendorForm.vendorName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="供应商税号" prop="vendorTaxCode" :label-width="formLabelWidth">
          <el-input v-model="vendorForm.vendorTaxCode" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="供应商税号" :label-width="formLabelWidth">
          <el-input v-model="vendorForm.vendorAddress" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="供应商银行" :label-width="formLabelWidth">
          <el-input v-model="vendorForm.vendorBank" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="供应商状态" :label-width="formLabelWidth">
          <el-select v-model="vendor.vendorStatus" placeholder="供应商状态">
            <el-option label="未激活" value="0"></el-option>
            <el-option label="已激活，未锁定" value="1"></el-option>
            <el-option label="已锁定" value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetvendorForm" size="small">取 消</el-button>
        <el-button type="primary"
                   @click="formStatus==='create' ? vendorFormSubmit('vendorRef') : vendorFormUpdate('vendorRef')"
                   size="small">确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import request from '@/utils/request'

export default {
  data() {
    return {
      small: 'small',
      drawer: false,
      tableData: [],
      multipleSelection: [],
      currentPage: 1,
      pageSize: 10,
      loading: true,
      pageTotal: 0,
      tableHeight: null,
      vendor: {
        vendorName: '',
        vendorCode: '',
        vendorStatus: '',
        deleted: 0
      },
      vendorForm: {
        id: undefined,
        deleted: '',
        vendorName: '',
        vendorCode: '',
        vendorStatus: '',
        vendorTaxCode: '',
        vendorAddress: '',
        vendorBank: ''
      },
      rules: {
        vendorName: [
          {required: true, message: '请填写供应商名称', trigger: 'blur'}
        ],
        vendorCode: [
          {required: true, message: '请填写供应商代码', trigger: 'blur'}
        ],
        vendorTaxCode: [
          {required: true, message: '请填写供应商税号', trigger: 'blur'}
        ],
      },
      formStatus: '',
      formStatusMap: {
        create: '新增供应商信息',
        update: '修改供应商信息',
      },
      dialogFormVisible: false,
      formLabelWidth: '120px'
    }
  },

  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.findByPage()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.findByPage()
    },
    onSubmit() {
      this.findByPage();
    },
    formatStatus: function (row) {
      if (row.vendorStatus === 0) {
        return "未激活"
      } else if (row.vendorStatus === 1) {
        return "已激活未锁定"
      } else if (row.vendorStatus === 2) {
        return "已锁定"
      } else {
        return "暂未状态"
      }
    },
    resetvendorForm() {
      this.dialogFormVisible = false
      this.vendorForm = {
        vendorName: '',
        vendorCode: '',
        vendorStatus: '',
        vendorTaxCode: '',
        vendorAddress: '',
        vendorBank: ''
      }
    },
    handleCreate() {
      this.dialogFormVisible = true
      this.formStatus = 'create'
      this.$nextTick(() => {
        this.$refs['vendorRef'].clearValidate()
      })
    },
    handleUpdate(row) {
      this.vendorForm = Object.assign({}, row)
      this.dialogFormVisible = true
      this.formStatus = "update"
      this.$nextTick(() => {
        this.$refs['vendorRef'].clearValidate()
      })
    },
    handleDelete(row) {
      this.vendorForm = Object.assign({}, row)
      this.vendorForm.deleted = 1

      return request({
        method: 'post',
        url: '/masterdata/vendor/updatevendor',
        contentType: 'application/json',
        data: this.vendorForm,
        baseURL: 'http://127.0.0.1:8093'
      }).then((response) => {
        if (response.data.id !== null) {
          this.$message({
            showClose: true,
            message: '删除成功',
            type: 'success',
          });

          this.findByPage()
        }
      })
    },
    vendorFormSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          return request({
            method: 'post',
            url: '/masterdata/vendor/createVendor',
            contentType: 'application/json',
            data: this.vendorForm,
            baseURL: 'http://127.0.0.1:8093'
          }).then((response) => {
            if (response.data.id !== null) {
              this.$message({
                showClose: true,
                message: '创建成功',
                type: 'success',
              });

              this.$refs[formName].resetFields()
              this.resetvendorForm()
              this.dialogFormVisible = false
              this.findByPage()
            }
          })
        }
      });
    },
    vendorFormUpdate(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          return request({
            method: 'post',
            url: '/masterdata/vendor/updateVendor',
            contentType: 'application/json',
            data: this.vendorForm,
            baseURL: 'http://127.0.0.1:8093'
          }).then((response) => {
            if (response.data.id !== null) {
              this.$message({
                showClose: true,
                message: '修改成功',
                type: 'success',
              });

              this.$refs[formName].resetFields()
              this.resetvendorForm()
              this.dialogFormVisible = false
              this.findByPage()
            }
          })
        }
      });
    },
    findByPage() {
      this.loading = true
      return request({
        url: `/masterdata/vendor/findByPage/${this.currentPage}/${this.pageSize}`,
        baseURL: 'http://127.0.0.1:8093',
        method: 'post',
        contentType: 'application/json',
        data: this.vendor
      }).then((response) => {
        this.tableData = response.data.content;
        this.pageTotal = response.data.totalElements;
        this.loading = false;
      })
    }
  }, mounted() {
    this.findByPage()
    this.tableHeight =
        window.innerHeight - this.$refs.topictable.$el.offsetTop - 80;
  },
}
</script>

<style>
.el-select {
  display: inline-block;
  position: relative;
  width: 100% !important;
}
</style>

